const initialState = {
    cities: [],
    stages: [],
    insurers: [],
    products: [],
    emailTemplates: [],
    whatsappTemplates: []
  }
  
  const SelectOptionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_STAGES':
        return { ...state, stages: action.stages }
      case 'GET_INSURERS':
        return { ...state, insurers: action.insurers }
      case 'GET_PROCESS_PRODUCTS':
        return { ...state, products: action.products }
      case 'GET_CITIES':
        return { ...state, cities: action.cities }
      case 'CLEAR_CITIES':
        return { ...state, cities: [] }
      case 'GET_EMAIL_TEMPLATES':
        return { ...state, emailTemplates: action.emailTemplates }   
      case 'GET_WHATSAPP_TEMPLATES':
        return { ...state, whatsappTemplates: action.whatsappTemplates }    
      default:
        return state
    }
  }
  
  export default SelectOptionsReducer