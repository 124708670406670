// You can customize the template with the help of this file

function getIcon () {
  const today = new Date() // Obtém a data atual
  const month = today.getMonth() + 1 // Retorna o mês (1-12)
  const day = today.getDate() // Retorna o dia do mês

  const defaultIcon = require('@src/assets/images/imagesbravo/icons/icon-png.png').default
  let icon

  if (month === 9 && day >= 1 && day <= 30) {
   icon = require('@src/assets/images/bravoThemes/bravo_icon_amarelo.png').default // Setembro
  } else if (month === 10 && day >= 1 && day <= 31) {
    icon = require('@src/assets/images/bravoThemes/bravo_icon_rosa.png').default // Outubro
  } else {
    icon = defaultIcon
  }
  
  return icon
}

//Template config options
const themeConfig = {
  app: {
    appName: 'Bravo Brasil',
    // appLogoImage: require('@src/assets/images/imagesbravo/icons/icon-png.png').default
    appLogoImage: getIcon()
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: true
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  }
}

export default themeConfig
