const initialState = {
  processLog: {
    data: [],
    pagination: {}
  },
  quotationInsurers: [],
  processTags: [],
  reasonsToLoss: [],
  reasonsToCanceled: [],
  fileTypes: [],
  insuranceStatus: [],
  brokerShortcuts: [],
  processQuotationInsuranceObs: [],
  companyContacts: []
}

const ProcessReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PROCESS_LOG':
      return { ...state, processLog: action.processLog}
    case 'GET_PROCESS_QUOTATION_INSURERS':
      return { ...state, quotationInsurers: action.quotationInsurers }  
    case 'GET_INSURANCE_STATUS':
      return { ...state, insuranceStatus: action.insuranceStatus }
    case 'GET_PROCESS_TAGS':
      return { ...state, processTags: action.processTags } 
    case 'GET_REASONS_TO_LOSS':
      return { ...state, reasonsToLoss: action.reasonsToLoss } 
    case 'GET_REASONS_TO_CANCELED':
      return { ...state, reasonsToCanceled: action.reasonsToCanceled }   
    case 'GET_PROCESS_REASON_TO_CANCELED':
      return { ...state, processReasonToCanceled: action.processReasonToCanceled } 
    case 'GET_FILE_TYPES':
      return { ...state, fileTypes: action.fileTypes }   
    case 'GET_BROKER_SHORTCUTS':
      return { ...state, brokerShortcuts: action.brokerShortcuts }
    case 'GET_PROCESS_QUOTATION_INSURANCE_OBS':      
      return { ...state, processQuotationInsuranceObs: action.processQuotationInsuranceObs }
    case 'GET_COMPANY_CONTACTS':
      return { ...state, companyContacts: action.companyContacts }  
    default:
      return state
  }
}

export default ProcessReducer